import { render } from "./ULifeCareSerialNumber.vue?vue&type=template&id=89c66aa4"
import script from "./ULifeCareSerialNumber.vue?vue&type=script&setup=true&lang=js"
export * from "./ULifeCareSerialNumber.vue?vue&type=script&setup=true&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "89c66aa4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('89c66aa4', script)) {
    api.reload('89c66aa4', script)
  }
  
  module.hot.accept("./ULifeCareSerialNumber.vue?vue&type=template&id=89c66aa4", () => {
    api.rerender('89c66aa4', render)
  })

}

script.__file = "src/views/serialNumber/components/ULifeCareSerialNumber.vue"

export default script