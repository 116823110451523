<template>
  <a-table
    bordered
    :data-source="filteredData"
    :columns="columns"
    rowKey="stationID"
  >
    <template #tmpGroup="{ record }">
      <div v-if="record.group">{{ record.group }}</div>
      <div v-else class="groupContent">{{ translateGroup(record.group) }}</div>
    </template>
  </a-table>
</template>

<script setup>
  import { computed, reactive, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'

  // Initialize composables
  const store = useStore()
  const { t } = useI18n()

  // Computed properties
  const serialNumber = computed(() => store.state.serialNumber.serialNumber)
  const getSetSerialNumber = () =>
    store.dispatch('serialNumber/getSetSerialNumber')

  // Table columns
  const columns = [
    {
      title: t('HS001.serialNumber'),
      dataIndex: 'stationID',
      width: '70%',
    },
    {
      title: t('HS001.assignedGroup'),
      dataIndex: 'group',
      width: '30%',
      slots: {
        customRender: 'tmpGroup',
      },
    },
  ]

  // Lifecycle hooks
  onMounted(() => {
    getSetSerialNumber()
  })

  // 將 filteredData 改成 computed property
  const filteredData = computed(() =>
    serialNumber.value.filter((item) =>
      item.stationID
        .toLowerCase()
        .includes('com.viwaveulife.healthstation.home')
    )
  )

  function translateGroup(group) {
    if (group) {
      return group
    } else {
      return t('HS001.unassigned')
    }
  }
</script>

<style lang="less" scoped>
  .groupContent {
    // 定義字為灰色
    color: #8c8c8c;
  }
</style>
