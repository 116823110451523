<template>
  <a-table
    bordered
    :data-source="filteredData"
    :columns="columns"
    rowKey="stationID"
  ></a-table>
</template>

<script setup>
  import { computed, reactive, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'

  // Initialize composables
  const store = useStore()
  const { t } = useI18n()

  // Computed properties
  const serialNumber = computed(() => store.state.serialNumber.serialNumber)
  const getSetSerialNumber = () =>
    store.dispatch('serialNumber/getSetSerialNumber')

  // Table columns
  const columns = [
    {
      title: t('HS001.serialNumber'),
      dataIndex: 'stationID',
      width: '100%',
    },
  ]

  // Lifecycle hooks
  onMounted(() => {
    getSetSerialNumber()
  })

  // 將 filteredData 改成 computed property
  const filteredData = computed(() =>
    serialNumber.value.filter((item) =>
      item.stationID.toLowerCase().includes('com.viwaveulife.healthcare.mobile')
    )
  )
</script>

<style lang="less" scoped></style>
