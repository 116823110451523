import { render } from "./StationHomeSerialNumber.vue?vue&type=template&id=5c03df5f&scoped=true"
import script from "./StationHomeSerialNumber.vue?vue&type=script&setup=true&lang=js"
export * from "./StationHomeSerialNumber.vue?vue&type=script&setup=true&lang=js"

import "./StationHomeSerialNumber.vue?vue&type=style&index=0&id=5c03df5f&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-5c03df5f"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5c03df5f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5c03df5f', script)) {
    api.reload('5c03df5f', script)
  }
  
  module.hot.accept("./StationHomeSerialNumber.vue?vue&type=template&id=5c03df5f&scoped=true", () => {
    api.rerender('5c03df5f', render)
  })

}

script.__file = "src/views/serialNumber/components/StationHomeSerialNumber.vue"

export default script