import { render } from "./index.vue?vue&type=template&id=3d40663a&scoped=true"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"

import "./index.vue?vue&type=style&index=0&id=3d40663a&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-3d40663a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3d40663a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3d40663a', script)) {
    api.reload('3d40663a', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=3d40663a&scoped=true", () => {
    api.rerender('3d40663a', render)
  })

}

script.__file = "src/views/serialNumber/index.vue"

export default script